import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useQuery } from '@apollo/client';
import reduce from 'lodash/reduce';
import max from 'lodash/max';
import { useSelectedCompany } from '../../../../hooks';
import { CONFIRMING_CREDIT_LINE } from '../../../../graphql';
import { formatMoney } from '../../../../helpers';
import ProgressBarCell from '../../../../components/cells/ProgressBarCell';

const ConfirmingCreditLine = (props) => {
  const company = useSelectedCompany();
  const { data, loading } = useQuery(CONFIRMING_CREDIT_LINE);
  const used = useMemo(() => reduce(
    data?.confirmingAnalytics,
    (sum, operation) => sum + operation.totalAmortization,
    0,
  ), [data]);
  const limit = useMemo(() => company?.currentCompanyConfirmingCreditLine?.limit, [company]);
  const available = useMemo(() => limit - used, [data, company]);
  if (loading) return <></>;
  return (
    <Stack
      width={{ xs: '100%', md: '80%', xl: '80%' }}
      spacing={1}
      backgroundColor="white"
      borderRadius={5}
      {...props}
    >
      <Typography fontSize={14}>
        Crédito disponible: ${formatMoney(max([available, 0]))}
      </Typography>
      <ProgressBarCell
        isMoney
        currency="CLP"
        value={used}
        total={limit}
        startTooltipTitle="Línea utilizada"
        endTooltipTitle="Línea total"
      />
    </Stack>
  );
};

export default ConfirmingCreditLine;
